import styled from "styled-components";
import { Constants } from "../../config/constants";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { usePlatformContext } from "config/context";

const colors = Constants.theme.colors;

export const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 0;
  padding: 0;

  &:hover {
    background: transparent;
    border-radius: 1000px;
    transition: 0.2s ease-out;
  }

  svg {
    font-size: ${(props) => (props.size ? props.size : "1.7rem")};
    color: ${(props) => props.color || colors.white};
  }
`;

export const CustomLink = (props) => {
  const context = usePlatformContext();
  return (
    <Link
      {...props}
      onClick={() => {
        if (props.card) {
          if (context.current !== "/services") {
            context.setLoading(true);
          }
          let element = document.getElementById(props.scrollLocation);
          console.log(element);
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
          }
        } else {
          context.setLoading(true);
          context.handleScrollUp();
        }

        if (props.to === "/") {
          context.setHeaderSolid(false);
          context.setCurrent(props.to);
        } else if (!props.to.includes("http")) {
          context.setHeaderSolid(true);
          context.setCurrent(props.to);
        }
        if (context.menu) context.toggleMenu();

        props.onClick && props.onClick();
      }}
    />
  );
};

export const StyledSubmitButton = styled.button`
  border: none;
  padding: 8px 12px;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "600"};
  background: ${(props) => props.background || colors.primary};
  height: ${(props) => props.height || "50px"};
  border-radius: 1000px;
  color: ${colors.white};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover {
    background: ${(props) => props.background || colors.primary};
    opacity: 0.9;
    transition: 0.2s ease-out;
  }

  &:active {
    transition: 0.2s ease-out;
    outline: none;
    border: 2px solid #1279fc;
  }

  &:disabled {
    background: ${colors.lightGrey};
    color: ${colors.darkGrey};
    cursor: not-allowed;
  }
`;
