import styled from "styled-components";
import { StyledHeader } from "./text";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

const ProgressWrapper = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${colors.mediumGrey};
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .progress {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: ${colors.secondary};
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
  }
`;

export const ProgressBar = (props) => {
  return (
    <>
      <StyledHeader fontSize={"0.8rem"}>{props.title}</StyledHeader>
      <ProgressWrapper>
        <div className='progress' style={{ width: `${props.progress}%` }}></div>
      </ProgressWrapper>
    </>
  );
};
