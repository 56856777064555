import { usePlatformContext } from "config/context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { AppRoutes } from "routes";
import { styled } from "styled-components";
import { Constants } from "config/constants";
import { Loading } from "components/utils/loading";
import { useCallback, useEffect, useState } from "react";

const colors = Constants.theme.colors;
const images = Constants.images;

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
  overflow: hidden;
  z-index: -100;

  display: flex;
  flex-direction: row;

  margin: ${(props) =>
    props.index ? `0 0 0 -${props.index * props.size}px` : "0"};

  transition: margin 0.5s ease-in-out;

  img {
    min-width: ${(props) => props.size}px;
    object-fit: cover;
  }
`;

const LoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: ${(props) => (props.loading ? 9999 : -999)};
  opacity: ${(props) => (props.loading ? 1 : 0)};
  background-color: ${(props) =>
    props.loading ? colors.charcoalGrey : "transparent"};
  transition: opacity 0.3s linear, z-index 0.3s linear,
    background-color 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackgroundHandler = () => {
  const [size, setSize] = useState(window.innerWidth);
  const [headerBg, setHeaderBg] = useState(1);

  const switchHeaderBg = useCallback(() => {
    let index = headerBg;
    if (index === Constants.images.headerBg.length - 1) {
      index = 0;
    } else index++;
    setHeaderBg(index);
  }, [headerBg]);

  const handleResize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      switchHeaderBg();
    }, 8000);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    };
  }, [switchHeaderBg]);

  return (
    <BackgroundContainer index={headerBg} size={size}>
      {images.headerBg.map((src, index) => (
        <img key={index} src={src} alt={"background" + index} />
      ))}
    </BackgroundContainer>
  );
};

function App() {
  const context = usePlatformContext();
  return (
    <>
      <ToastContainer
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme='light'
      />
      <LoadingContainer loading={context.loading}>
        <Loading />
      </LoadingContainer>
      <BackgroundHandler />
      <AppRoutes loading={context.loading} />
    </>
  );
}

export default App;
