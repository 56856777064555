import styled from "styled-components";
import { StyledLink } from "./text";
import { CustomLink } from "./buttons";
import { Image } from "./image";

export const Card = (props) => {
  return (
    <CardWrapper to={props.link} card={true} scrollLocation={props.scroll}>
      <CardImage>
        <Image src={props.image} alt={props.title} />
      </CardImage>
      <CardTextWrapper>
        <CardTitle>{props.title}</CardTitle>
        <StyledLink to={props.link} card={true} scrollLocation={props.scroll}>
          Mehr erfahren
        </StyledLink>
      </CardTextWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled(CustomLink)`
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
  min-width: 300px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 350px;
  margin: 10px;
  padding-bottom: 10px;
  flex: 1;

  @media (max-width: 768px) {
    max-width: 100%;
    min-width: 0;
    margin-left: 0;
  }

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  }
`;

const CardImage = styled.div`
  width: 100%;
  .custom-image {
    width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
  }
`;

const CardTextWrapper = styled.div`
  padding: 10px;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
`;
