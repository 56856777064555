import { Team } from "./dummy";

export const Constants = {
  theme: {
    colors: {
      primary: "#93cd1c", // Blue (buttons, etc.)
      secondary: "#646467", // Orange (buttons, etc.)
      white: "#FFFFFF", // White (backgrounds, etc.)
      lightGrey: "#F5F5F5", // Light Grey (inputs, etc.)
      mediumGrey: "#D3D3D3", // Medium Grey (borders, etc.)
      darkGrey: "#A9A9A9", // Dark Grey (paragraphs, info, formfield text etc.)
      charcoalGrey: "#000", // Charcoal Grey (important text, etc.)
    },
  },
  images: {
    logo: "/logo.png",
    headerBg: [
      "/header-1.jpg",
      "/header-2.jpeg",
      "/header-3.jpeg",
      "/header-4.jpeg",
    ],
  },
  team: Team,
};
