import styled from "styled-components";
import { Constants } from "../../config/constants";

import { IoMailSharp } from "react-icons/io5";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaMapMarkerAlt } from "react-icons/fa";
import { StyledLink, StyledParagraph } from "components/utils/text";
import { HeaderBlock } from "./header-section";
import { EmbedGoogleMaps } from "components/utils/google-maps";

const colors = Constants.theme.colors;

const ContactWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;

  .container {
    min-height: 500px;
    padding: 25px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const ContactInfoWrapper = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding: 0 25px;

  @media (max-width: 768px) {
  }
`;

const ContactFormWrapper = styled.div`
  padding: 5px !important;
  border-radius: 10px;
  background-color: ${colors.mediumGrey};
  display: flex;
  margin: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  iframe {
    border-radius: 10px;
    margin: 0;
    width: 100%;
    height: 100%;
    flex: 1;
  }
`;

const ContactInfoList = styled.div`
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p,
  a {
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
    color: ${colors.charcoalGrey};

    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }

  p {
    color: ${colors.secondary};
  }

  a:hover {
    color: ${colors.darkGrey};
    transition: all 0.3s ease-in-out;
  }

  svg {
    font-size: 1.2rem;
    margin-right: 25px;
  }

  .item-wrapper {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

export const ContactForm = (props) => {
  return (
    <ContactWrapper {...props}>
      <ContactFormWrapper className='container'>
        <EmbedGoogleMaps />
      </ContactFormWrapper>
      <ContactInfoWrapper className='container'>
        <HeaderBlock
          header='Contact our support team to grow your business'
          subheader='Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.'
          center={false}
          hr={false}
          category='Get in Touch'
        />
        <ContactInfoList>
          <div className='item-wrapper'>
            <FaMapMarkerAlt />
            <StyledParagraph>
              Glatzau 58 – A-8082 Kirchbach in Steiermark
            </StyledParagraph>
          </div>

          <div className='item-wrapper'>
            <IoMailSharp />
            <StyledLink to={"tel:+43 664 3890746"}>+43 664 3890746</StyledLink>
          </div>
          <div className='item-wrapper'>
            <BsFillTelephoneFill />
            <StyledLink to={"mailto:office@tga-projekt.at"}>
              office@tga-projekt.at
            </StyledLink>
          </div>
        </ContactInfoList>
      </ContactInfoWrapper>
    </ContactWrapper>
  );
};
