import { useState } from "react";
import styled from "styled-components";

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;

  &:hover {
    img,
    div {
      transform: ${(props) => `scale(${props.zoom})`};
      transition: transform 1.8s ease-in-out;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: ${(props) => props.opacity || 0} !important;
  background-color: ${(props) => props.color || "transparent"};
`;

export const Image = ({
  alt = "image",
  opacity = 0,
  color = "black",
  src = "https://via.placeholder.com/150",
  zoom = 1,
  hover = 0,
}) => {
  if (!hover && opacity > 0 && opacity < 1) hover = opacity;
  const [enter, setEnter] = useState(false);
  return (
    <ImageWrapper zoom={zoom} className='custom-image'>
      <Img src={src} alt={alt} className='custom-image' />
      <Overlay
        opacity={enter ? hover : opacity}
        color={color}
        className='custom-image'
        onMouseEnter={() => setEnter(true)}
        onMouseLeave={() => setEnter(false)}
      />
    </ImageWrapper>
  );
};
