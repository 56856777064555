import { SelectBox } from "components/utils/boxes";
import { Image } from "components/utils/image";
import { StyledLink } from "components/utils/text";

export const ProjectGridElement = (props) => {
  const { id, image, title } = props.data;

  return (
    <SelectBox to={`/projects/${id}`}>
      <Image src={image} alt='placeholder' opacity={0.3} />
      <p>
        {title} <br />
        <br />
        <StyledLink to={`/projects/${id}`}>View Project</StyledLink>
      </p>
    </SelectBox>
  );
};
