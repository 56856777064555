import { Constants } from "config/constants";
import styled from "styled-components";
import { StyledHeader, StyledParagraph } from "../utils/text";

const colors = Constants.theme.colors;

const HeaderBlockWrapper = styled.div`
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.center ? "center" : "flex-start")};
  width: 100%;
  text-align: ${(props) => (props.center ? "center" : "left")};

  .category {
    font-size: 1rem;
    font-weight: 600;
    color: ${colors.secondary};
    margin-bottom: 10px;
  }

  .header {
    margin-bottom: 10px;
  }

  .sub-header {
    width: ${(props) => (props.center ? "80%" : "100%")};
    font-size: 1.1rem;
    font-weight: 500;
    color: ${colors.secondary};
    margin-bottom: ${(props) => (props.hr ? "10px" : "50px")};
  }

  hr {
    width: 100%;
    display: ${(props) => (props.hr ? "initial" : "none")};
    border: 0.5px solid ${colors.mediumGrey};
  }
`;

export const HeaderBlock = (props) => {
  let category = false;
  let hr = true;
  let center = true;
  if (props.hr === false) {
    hr = false;
  }
  if (props.center === false) {
    center = false;
  }
  props.category ? (category = props.category) : (category = false);
  return (
    <HeaderBlockWrapper hr={hr} center={center}>
      {category && (
        <StyledParagraph className='category'>{props.category}</StyledParagraph>
      )}
      <StyledHeader className='header'>{props.header}</StyledHeader>
      <StyledParagraph className='sub-header'>
        {props.subheader}
      </StyledParagraph>
      <hr />
    </HeaderBlockWrapper>
  );
};
