// import { useMediumBlogs } from "hooks/getBlogs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as Scroll from "react-scroll";

const Context = React.createContext();

export const ContextWrapper = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState(false);
  // const { getBlogs } = useMediumBlogs();
  // const [blogs, setBlogs] = useState(null);

  const nav = useNavigate();

  const navigate = (path) => {
    if (path === "/") {
      setHeaderSolid(false);
      setCurrent(path);
    } else {
      setCurrent(path);
      setHeaderSolid(true);
    }
    if (menu) toggleMenu();
    setLoading(true);
    handleScrollUp();
    nav(path);
  };

  const toggleMenu = () => {
    setMenu(!menu);
  };

  const scroll = Scroll.animateScroll;
  const [headerSolid, setHeaderSolid] = useState(
    window.location.pathname !== "/"
  );
  const [current, setCurrent] = useState(window.location.pathname);

  const handleScroll = useCallback(() => {
    if (window.location.pathname === "/") {
      setCurrent(window.location.pathname);
      if (window.scrollY > window.innerHeight - 80) {
        setHeaderSolid(true);
      } else {
        setHeaderSolid(false);
      }
    } else if (!headerSolid) {
      setHeaderSolid(true);
    }
  }, [headerSolid]);

  useEffect(() => {
    // if (blogs === null)
    //   getBlogs().then((res) => {
    //     if (res) {
    //       const data = res.items;
    //       let entries = [];
    //       data.forEach((item, index) => {
    //         let entry = {};
    //         entry.image = item.thumbnail;
    //         entry.id = index;
    //         entry.link = item.guid;
    //         entry.title = item.title;
    //         entries.push(entry);
    //       });
    //       setBlogs(entries);
    //     }
    //   });
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, handleScroll]);

  const redirect = (path) => {
    window.location.replace(path);
  };

  const handleScrollUp = () => {
    scroll.scrollToTop({
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  };

  const handleStartScroll = () => {
    scroll.scrollTo(document.getElementById("content").offsetTop - 75, {
      duration: 1000,
      delay: 100,
      smooth: true,
    });
  };

  const contextData = {
    navigate,
    loading,
    setLoading,
    redirect,
    current,
    setCurrent,
    handleScrollUp,
    headerSolid,
    setHeaderSolid,
    handleStartScroll,
    menu,
    toggleMenu,
    // blogs,
  };

  return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

export const usePlatformContext = () => {
  return useContext(Context);
};
