import { styled } from "styled-components";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.section`
  width: 100%;
  height: auto;
`;

export const SectionWrapper = (props) => {
  const { children, id } = props;
  return <Section id={id}>{children}</Section>;
};
