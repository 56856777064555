import styled from "styled-components";
import { Card } from "../utils/card";

const CardSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  width: 100%;
  padding: 0 25px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const CardSecion = (props) => {
  return (
    <CardSectionWrapper>
      <Card
        image={"https://via.placeholder.com/400"}
        title={"Consulting"}
        link={"/services"}
        scroll={"service1"}
      />
      <Card
        image={"https://via.placeholder.com/400"}
        title={"Planungsleistungen"}
        link={"/services"}
        scroll={"service2"}
      />
      <Card
        image={"https://via.placeholder.com/400"}
        title={"Überwachungsleistungen"}
        link={"/services"}
        scroll={"service3"}
      />
    </CardSectionWrapper>
  );
};
