export const EmbedGoogleMaps = () => {
  return (
    <iframe
      title='Google Maps Location'
      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2725.6149670775185!2d15.678011776387915!3d46.9102902358013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476faeadc710a147%3A0xcbb1e138366543d6!2sTga%20Projekt!5e0!3m2!1sde!2sat!4v1688556923641!5m2!1sde!2sat'
      style={{ border: "0" }}
      allowfullscreen=''
      loading='eager'
      referrerpolicy='no-referrer-when-downgrade'
    ></iframe>
  );
};
