import styled from "styled-components";

export const HomeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin: 20px;

  .element {
    grid-column: span 2;
    @media (max-width: 768px) {
      grid-column: span 3;
    }

    @media (max-width: 500px) {
      grid-column: span 6;
    }
  }

  #item2 {
    grid-column: span 3;

    @media (max-width: 500px) {
      grid-column: span 6;
    }
  }

  #item1 {
    grid-column: span 3;

    @media (max-width: 500px) {
      grid-column: span 6;
    }
  }
`;
