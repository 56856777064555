import React from "react";
import { ProjectGridElement } from "sections/home/home-box";
import { latestProjects } from "config/dummy";
import { HeaderBlock } from "components/sections/header-section";
import { HomeGrid } from "components/grids/home-grid";

function Home() {
  const data = latestProjects;
  return (
    <>
      <HeaderBlock
        header={"Willkommen bei Tga Projekt"}
        subheader={"Hier finden Sie ein paar unserer aktuellen Projekte."}
      />
      <HomeGrid>
        {data.map((item, index) => {
          return (
            <div className='element' id={"item" + (index + 1)}>
              <ProjectGridElement key={item.id} data={item} />
            </div>
          );
        })}
      </HomeGrid>
    </>
  );
}

export default Home;
