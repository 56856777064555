import styled from "styled-components";

import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const ProjectsHeader = styled.div`
  width: 100%;
  color: ${colors.darkGrey};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  h3 {
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0 20px;

    &:hover {
      color: ${colors.secondary};
      opacity: 0.8;
    }

    @media (max-width: 600px) {
      margin-top: 8px;
    }
  }

  .active {
    color: ${colors.secondary};
    font-weight: 550;

    &:hover {
      opacity: 1;
      font-weight: 500;
    }

    &:active {
      opacity: 1;
      font-weight: 600;
    }
  }
`;
