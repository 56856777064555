import DetailProjectSection from "sections/projects/detail-project";
import { StyledHeader } from "components/utils/text";
import { projectsData } from "config/dummy";
import { useParams } from "react-router";

function SingleProject() {
  const data = projectsData;
  const { id } = useParams();
  let detail = data.find((item) => item.id === Number.parseInt(id));
  if (detail) {
    return <DetailProjectSection details={detail} />;
  } else {
    return (
      <StyledHeader textAlign='center'>Projekt nicht gefunden</StyledHeader>
    );
  }
}

export default SingleProject;
