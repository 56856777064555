import { TeamGrid } from "components/grids/team-grid";
import styled from "styled-components";
import { Section } from "./section-wrapper";
import { Constants } from "config/constants";
import { StyledLink } from "components/utils/text";

const colors = Constants.theme.colors;

const TeamElement = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 350px;
  padding: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1.8s ease-in-out;
  }

  &:hover {
    .overlay {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px;
    background-color: black;
    display: flex;
    justify-content: end;
    opacity: 0;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s ease-in-out;

    @media (max-width: 768px) {
      opacity: 1;
    }
  }

  .text {
    width: 100%;
    background-color: green;
    color: ${colors.secondary};
    text-align: left;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    height: auto;
    border-radius: 5px;

    h2 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0.5rem;
    }

    h3 {
      font-size: 1.1rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    a {
      color: ${colors.secondary};
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const TeamSection = () => {
  const data = Constants.team;
  return (
    <Section>
      <TeamGrid>
        {data.map((item, index) => {
          return (
            <div className='element' id={"item" + (index + 1)} key={item.id}>
              <TeamElement>
                <img src={item.image} alt={item.name} />
                <div className='overlay'>
                  <div className='text'>
                    <h2>{item.name}</h2>
                    <h3>{item.position}</h3>
                    <StyledLink to={`mailto:${item.email}`}>
                      {item.email}
                    </StyledLink>
                    <p>{item.description}</p>
                  </div>
                </div>
              </TeamElement>
            </div>
          );
        })}
      </TeamGrid>
    </Section>
  );
};
