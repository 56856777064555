import { ContactForm } from "components/sections/contact-section";
import { HeaderBlock } from "components/sections/header-section";
import { PageWrapper } from "components/sections/section-wrapper";
import { TeamSection } from "components/sections/team-section";
import React from "react";

function Contact() {
  return (
    <PageWrapper>
      <HeaderBlock
        header={"Kontaktiere Uns"}
        hr={false}
        subheader={
          "Bei eventuellen Fragen bitte rufen Sie uns einfach unter der verlinkten Nummer an, oder schreiben Sie uns eine Mail an unsere Office Mail Addresse"
        }
      />
      <ContactForm title={"Google Maps"} text={"loading ..."} />

      <HeaderBlock
        header={"Deine Anprechpartner"}
        subheader={"Hier findest du nochmal unser gesamtes Team."}
      />
      <TeamSection />
    </PageWrapper>
  );
}

export default Contact;
