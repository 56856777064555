import { StyledLink } from "components/utils/text";
import styled from "styled-components";

const Wrappper = styled.div`
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
`;

function NotFound() {
  return (
    <Wrappper>
      <h1>404</h1>
      <p>Page Not Found</p>
      <StyledLink
        color={"white"}
        fontWeight={"bold"}
        to='/'
        reloadDocument={true}
      >
        Go Back Home
      </StyledLink>
    </Wrappper>
  );
}

export default NotFound;
