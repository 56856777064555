import styled from "styled-components";
import { Constants } from "config/constants";
import { CustomLink } from "./buttons";

const colors = Constants.theme.colors;

export const StyledParagraph = styled.p`
  font-size: ${(props) => props.fontSize || "1.1rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  color: ${(props) => props.color || colors.darkGrey};
  margin: ${(props) => props.margin || "5px 0 "};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  max-width: ${(props) => props.maxWidth};
`;

export const StyledLink = styled(CustomLink)`
  font-size: ${(props) => props.fontSize || "0.9rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  color: ${(props) => props.color || colors.darkGrey};
  margin: ${(props) => props.margin || "5px 0 "};
  text-align: ${(props) => props.textAlign};
`;

export const StyledHeader = styled.h1`
  font-size: ${(props) => props.fontSize || "2.3rem"};
  font-weight: ${(props) => props.fontWeight || "600"};
  color: ${(props) => props.color || colors.charcoal};
  margin: ${(props) => props.margin || "5px 0 "};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  cursor: ${(props) => props.cursor};
`;
