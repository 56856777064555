import { Route, Routes } from "react-router";

export const RouteWrapper = (props) => {
  const createRoute = ({ element, children, ...route }) => {
    const Component = element;
    const WrappedComponent = () => (
      <>
        <Component />
      </>
    );
    return (
      <Route
        key={route.path}
        {...route}
        path={route.path && route.path}
        element={<WrappedComponent loading={props.loading} />}
      >
        {children && children.map(createRoute)}
      </Route>
    );
  };

  return <Routes>{props.routes.map(createRoute)}</Routes>;
};
