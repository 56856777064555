import { CardSecion } from "components/sections/card-section";
import { HeaderBlock } from "components/sections/header-section";
import {
  PageWrapper,
  SectionWrapper,
} from "components/sections/section-wrapper";
import { SectionBlock } from "components/sections/text-section";
import React from "react";

function Services() {
  return (
    <PageWrapper>
      <HeaderBlock
        header={"Unsere Leistungen"}
        subheader={
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla."
        }
      />
      <CardSecion />
      <SectionWrapper id='service1'>
        <SectionBlock reverse={true} type='image' />
      </SectionWrapper>
      <SectionWrapper id='service2'>
        <SectionBlock />
      </SectionWrapper>
      <SectionWrapper id='service3'>
        <SectionBlock reverse={true} type='image' />
      </SectionWrapper>
    </PageWrapper>
  );
}

export default Services;
