const images = [
  "/header-1.jpg",
  "/header-2.jpeg",
  "/header-3.jpeg",
  "/header-4.jpeg",
];

export const projectsData = [
  {
    id: 1,
    title: "Spar Lager",
    client: "Spar",
    location: "Vienna",
    date: "10.05.2021",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl.",
    category: "Exterior",
    image: "/header-1.jpg",
    images: images,
  },
  {
    id: 2,
    title: "Spar Lager",
    client: "Spar",
    location: "Vienna",
    date: "10.05.2021",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl.",
    category: "Interior",
    image: "/header-2.jpeg",
    images: images,
  },
  {
    id: 3,
    title: "Spar Lager",
    client: "Spar",
    location: "Vienna",
    date: "10.05.2021",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl.",
    category: "Exterior",
    image: "/header-3.jpeg",
    images: images,
  },
  {
    id: 4,
    title: "Spar Lager",
    client: "Spar",
    location: "Vienna",
    date: "10.05.2021",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl.",
    category: "Public",
    image: "/header-4.jpeg",
    images: images,
  },
  {
    id: 5,
    title: "Spar Lager",
    client: "Spar",
    location: "Vienna",
    date: "10.05.2021",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl. Nulla euismod, nisl eget fermentum aliquam, quam nisl ultricies nunc, quis aliquam nisl nunc quis nisl.",
    category: "Residental",
    image: "/header-3.jpeg",
    images: images,
  },
];

export const categories = [
  "All",
  "Exterior",
  "Interior",
  "Public",
  "Residental",
];

export const Team = [
  {
    id: 1,
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/400",
    email: "schweigler@tga-projekt.at",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/400",
    email: "schweigler@tga-projekt.at",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/400",
    email: "schweigler@tga-projekt.at",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/400",
    email: "schweigler@tga-projekt.at",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/400",
    email: "schweigler@tga-projekt.at",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
  {
    id: 2,
    name: "John Doe",
    position: "CEO",
    image: "https://via.placeholder.com/400",
    email: "schweigler@tga-projekt.at",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  },
];

export const latestProjects = projectsData.slice(0, 5);
