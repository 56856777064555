import { projectsData } from "config/dummy";
import { ProjectsHeader } from "sections/projects/header.projects";
import { ProjectSelectBox } from "sections/projects/project-box";
import { HeaderBlock } from "components/sections/header-section";
import { useState } from "react";
import { ProjectGrid } from "components/grids/project-grid";

function Projects() {
  const data = projectsData;
  const filters = [
    "All Projects",
    "Interior",
    "Exterior",
    "Residental",
    "Public",
  ];
  const categories = ["*", "Interior", "Exterior", "Residental", "Public"];
  const [filter, setFilter] = useState(0);
  return (
    <>
      <HeaderBlock
        header={"Unsere Projekte"}
        subheader={
          "Wir haben viele Projekte in verschiedenen Teilen der Welt abgeschlossen und vielen glücklichen Familien geholfen"
        }
      />
      <ProjectsHeader>
        {filters.map((item, index) => {
          return (
            <h3
              key={index}
              className={filters[filter] === item && "active"}
              onClick={() => {
                setFilter(filters.indexOf(item));
              }}
            >
              {item}
            </h3>
          );
        })}
      </ProjectsHeader>
      <ProjectGrid>
        {data
          .filter(
            (item) => item.category === categories[filter] || filter === 0
          )
          .map((item) => {
            return (
              <div className='element'>
                <ProjectSelectBox
                  id={item.id}
                  key={item.id}
                  title={item.title}
                  image={item.image}
                />
              </div>
            );
          })}
      </ProjectGrid>
    </>
  );
}

export default Projects;
