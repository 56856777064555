import { StyledIconButton } from "components/utils/buttons";
import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";
import { Constants } from "config/constants";
import { useNavigate } from "react-router";
import { usePlatformContext } from "config/context";
import {
  StyledHeader,
  StyledLink,
  StyledParagraph,
} from "components/utils/text";
import { Image } from "components/utils/image";

const colors = Constants.theme.colors;

export const DetailProject = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-color: #f5f5f5;
  color: #000;
  text-align: center;
  padding: 50px 20px;

  .content {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .content-info {
    display: flex;
    flex-direction: column;
    margin-left: 40px;

    @media (max-width: 768px) {
      margin-left: 0;
      margin-top: 20px;
    }

    h3 {
      font-size: 1.3rem;
      font-weight: 400;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 30px;
    }
  }

  .content-text {
    display: flex;
    width: 70%;
    flex-direction: column;

    .category {
      font-size: 1.2rem;
      color: ${colors.secondary};
      font-weight: 600;
    }

    h1 {
      margin-bottom: 30px;
      font-size: 4rem;

      @media (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    p {
      font-size: 1.4rem;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .custom-image {
    max-height: 300px;
    margin-bottom: 50px;
    border-radius: 10px;
  }

  .back {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    a {
      color: ${colors.charcoalGrey};
      font-size: 1.1rem;
      font-weight: 500;
    }

    .button {
      margin-right: 5px;
    }
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
    margin-bottom: 30px;

    .image-grid-element {
      grid-column: span 2;

      @media (max-width: 768px) {
        grid-column: span 3;
      }

      @media (max-width: 500px) {
        grid-column: span 6;
      }

      .custom-image {
        border-radius: 0;
        width: 100%;
        height: 100%;
        max-height: 300px;
      }
    }
  }
`;

function DetailProjectSection(props) {
  const context = usePlatformContext();
  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
    context.handleScrollUp();
  };
  const details = props.details;
  return (
    <DetailProject>
      <Image src={details.image} alt='placeholder' opacity={0.2} zoom={1.5} />
      <div className='content'>
        <div className='content-text'>
          <StyledHeader>{details.title}</StyledHeader>
          <StyledParagraph className='category'>Beschreibung:</StyledParagraph>
          <StyledParagraph>{details.description}</StyledParagraph>
        </div>
        <div className='content-info'>
          <h3>Auftraggeber</h3>
          <p>{details.client}</p>
          <h3>Datum</h3>
          <p>{details.date}</p>
          <h3>Ort</h3>
          <p>{details.location}</p>
          <h3>Kategrie</h3>
          <p>{details.category}</p>
        </div>
      </div>
      <div className='image-grid'>
        {details.images.map((image, index) => {
          return (
            <div className={"image-grid-element"}>
              <Image
                src={image}
                alt={"image" + index}
                opacity={0.2}
                zoom={1.5}
              />
            </div>
          );
        })}
      </div>
      <div className='back'>
        <div className='button'>
          <StyledIconButton color={colors.charcoalGrey} onClick={navigateBack}>
            <IoIosArrowBack />
          </StyledIconButton>
        </div>
        <StyledLink to={-1}>Zurück</StyledLink>
      </div>
    </DetailProject>
  );
}

export default DetailProjectSection;
