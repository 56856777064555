import { ProjectBox } from "components/utils/boxes";
import { Image } from "components/utils/image";

export const ProjectSelectBox = (props) => {
  const { title, image, id } = props;
  return (
    <ProjectBox to={`/projects/${id}`}>
      <Image src={image} alt='placeholder' opacity={0.3} zoom={1.5} />
      <p>
        {title} <br /> View More
      </p>
    </ProjectBox>
  );
};
