// import About from "pages/about";
import Contact from "pages/contact";
import Home from "pages/home";
import Projects from "pages/projects";
import Services from "pages/services";
import SingleProject from "pages/single-project";
import NotFound from "pages/404";
import React from "react";

export const routes = [
  {
    path: "*",
    element: NotFound,
  },
  {
    element: React.lazy(() => import("components/layout")),
    children: [
      {
        path: "/",
        element: Home,
      },
      // {
      //   path: "/about",
      //   element: About,
      // },
      {
        path: "/services",
        element: Services,
      },
      {
        path: "/projects",
        element: Projects,
      },
      {
        path: "/projects/:id",
        element: SingleProject,
      },
      {
        path: "/contact",
        element: Contact,
      },
      {
        path: "privacy",
        element: React.lazy(() => import("pages/privacy")),
      },
      {
        path: "imprint",
        element: React.lazy(() => import("pages/imprint")),
      },
    ],
  },
];
