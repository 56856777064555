import styled from "styled-components";
import { CustomLink } from "./buttons";

export const SelectBox = styled(CustomLink)`
  position: relative;
  display: flex;
  background-color: gray;
  height: 350px;
  transition: transform 0.3s;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    .custom-image {
      transform: scale(1.5);
    }

    p {
      display: block;
      font-weight: 500;
      a {
        display: block;
        text-decoration: underline;
      }
    }
  }

  .custom-image {
    width: 100%;
    object-fit: cover;
    transition: transform 1.5s ease-out, opacity 0.8s;
  }

  p {
    max-width: 400px;
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    color: white;
    font-size: 1.2rem;
    text-align: left;

    a {
      color: white;
      text-decoration: none;
    }
  }
`;

export const ProjectBox = styled(SelectBox)`
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    p {
      display: block;
      font-weight: 500;
    }
  }

  .custom-image {
    height: 100%;
    object-fit: cover;
  }

  p {
    top: auto;
    left: auto;
    max-width: 300px;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
    display: none;
  }
`;
