import styled from "styled-components";
import { StyledHeader, StyledParagraph } from "../utils/text";
import { ProgressBar } from "components/utils/progress";
import { Image } from "components/utils/image";

export const SectionBlockWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;
  min-height: 500px;

  .section-name {
    font-size: 0.9rem;
    font-weight: 700;
  }

  .section-image {
    padding: 0 25px;
    width: 50%;
    display: flex;
    justify-content: ${(props) => (props.reverse ? "flex-start" : "flex-end")};
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .section-image {
      width: 100%;
      margin: 10px;

      .custom-image {
        width: 100%;
        height: 100%;
      }
    }
    min-height: 0px;
    margin-bottom: 100px;
  }
`;

export const BlockWrapper = styled.div`
  padding: 25px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

export const SectionTextBlock = () => {
  return (
    <BlockWrapper>
      <StyledParagraph className='section-name'>Section Name</StyledParagraph>
      <StyledHeader>Section Header</StyledHeader>
      <StyledParagraph>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam
        voluptatum, quibusdam, quia, quos voluptates voluptatibus quod
        voluptatem quas doloribus quidem fugiat. Quisquam voluptatum, quibusdam,
        quia, quos voluptates voluptatibus quod voluptatem quas doloribus quidem
      </StyledParagraph>
    </BlockWrapper>
  );
};

export const SectionImageBlock = () => {
  return (
    <div className='section-image'>
      <Image src='https://via.placeholder.com/400' alt='placeholder' />
    </div>
  );
};

const SectionProgressBlock = () => {
  return (
    <BlockWrapper>
      <ProgressBar title={"HTML"} progress={90} />
      <ProgressBar title={"CSS"} progress={60} />
      <ProgressBar title={"JavaScript"} progress={90} />
      <ProgressBar title={"React"} progress={90} />
    </BlockWrapper>
  );
};

export const SectionBlock = (props) => {
  return (
    <SectionBlockWrapper reverse={props.reverse && props.reverse}>
      <SectionTextBlock />
      {props.type === "image" ? (
        <SectionImageBlock />
      ) : (
        <SectionProgressBlock />
      )}
    </SectionBlockWrapper>
  );
};
